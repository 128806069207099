<template>
  <div class="page-not-found min-h-60vh">
    <div class="outer-container">
      <div class="inner-container">
        <h1 class="font-bold">Oops...</h1>
        <p class="mt-12">We apologise, but the page you are looking for does not seem to exist.</p>
        <div class="mt-6" v-if="$auth.isAuthenticated">
          <p>Some of our most popular CONNECT pages can be found below.</p>
          <ul class="mt-8">
            <li>
              <router-link to="/expo" class="mt-4 hover:text-primary-500">
                - Expo
              </router-link>
            </li>
            <li>
              <router-link to="/discover" class="mt-4 hover:text-primary-500">
                - Discover
              </router-link>
            </li>
<!--            <li>-->
<!--              <router-link to="/conversations" class="mt-4 hover:text-primary-500">-->
<!--                - Conversations-->
<!--              </router-link>-->
<!--            </li>-->
          </ul>
        </div>
        <div class="mt-6" v-else>
          <router-link to="/" class="hover:text-primary-500">- Return home</router-link>
        </div>
        <div class="mt-12">
          If you have any enquiries please email us at <a href="mailto:connect@wineaustralia.com" class="hover:text-primary-500">connect@wineaustralia.com</a>.
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'PageNotFound'
}
</script>

<style scoped lang="scss">

</style>
